import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
    // component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  // },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/UnderMaintenanceView.vue')
  },
  {
    path: '/our-food',
    name: 'ourfood',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OurFoodView.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import(/* webpackChunkName: "about" */ '../views/OrderView.vue')
  },
  {
    path: '/locations',
    name: 'locations',
    component: () => import(/* webpackChunkName: "about" */ '../views/LocationsView.vue')
  },
  {
    path: '/app-download',
    name: 'appdownload',
    component: () => import(/* webpackChunkName: "about" */ '../views/AppDownloadView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue')
  },
  {
    path: '/returns-policy',
    name: 'returnspolicy',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReturnsPolicy.vue')
  },
  {
    path: '/competition',
    name: 'competition',
    component: () => import(/* webpackChunkName: "about" */ '../views/CarCompetitionView.vue')
  },
  {
    path: '/competition/terms',
    name: 'competitionterms',
    component: () => import(/* webpackChunkName: "about" */ '../views/CarCompetitionTermsView.vue')
  },
  {
    path: '/socials',
    name: 'socials',
    component: () => import(/* webpackChunkName: "about" */ '../views/SocialsView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(() => {
  window.scrollTo(0, 0)
})

export default router
