/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';



const store = new Vuex.Store({
  state: {
    navBarVisible: false,
    footerVisible: false,
   
  },
});

export default store;